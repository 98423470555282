<template>
	<div class="login-bg">
		<div class="login-box">
			<div class="left">
				<img class="bg" src="../../assets/img/logo.jpg" />
			</div>
			<div class="right">
				<h1>陵政通在线客服管理系统</h1>
				<div class="login-li">
					<el-input v-model="form.username" :autofocus="true" @keyup.enter="submitForm" type="text" clearable
						placeholder="请输入用户名" />
				</div>
				<div class="login-li">
					<el-input v-model="form.password " @keyup.enter="submitForm" show-password type="password" clearable
						placeholder="请输入密码" />
				</div>
				<div class="login-li">
					<el-button color="#4ba6df" class="login-btn el-white" @click="submitForm" type="primary">
						立即登录</el-button>
				</div>
			</div>
		</div>
	</div>
</template>


<script>
	import {
		ElMessage
	} from 'element-plus'
	import {
		request,
		api
	} from "@/api/login";
	export default {
		name: 'login',
		data() {
			return {
				form: {
					username: '',
					password: '',
				}
			}
		},
		created() {},
		methods: {
			// 登录
			submitForm() {
				if (this.form.username == '') {
					ElMessage({
						message: '请输入用户名',
						type: 'warning',
					})
				} else if (this.form.password == '') {
					ElMessage({
						message: '请输入密码',
						type: 'warning',
					})
				} else {
					var that = this
					this.$http.post(api.login, this.form)
						.then((res) => {
							// 存token
							sessionStorage.setItem('token', res.data.token)
							setTimeout(function() {
								that.$router.replace('/')
							}, 200)
						}).catch(function(err) {
						})
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.login-bg {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100vh;
		background: url(../../assets/img/login_bg.jpg);
		background-size: 100% 100%;

		.right {
			flex: 1;

			h1 {
				font-size: 28px;
				text-align: center;
				color: #4ba6df;
				font-weight: bold;
				margin-top: 10px;
				margin-bottom: 30px;
			}

			.icp {
				text-align: center;
			}

			.login-li {
				position: relative;
				margin-bottom: 20px;
				
				
				.login-btn {
					margin-top: 20px;
					width: 100%;
				}

				img {
					position: absolute;
					right: 1px;
					top: 1px;
					cursor: pointer;
					height: 38px;
					width: 110px;
				}
			}
		}

		.left {
			flex: 1;
			padding-right: 60px;
			display: flex;
			text-align: center;
			align-items: center;
			justify-content: center;

			.bg {
				display: block;
				width: 67%;
			}
		}

		.login-box {
			max-width: 800px;
			display: flex;
			background-color: #fff;
			border-radius: 10px;
			padding: 80px 60px;
		}
	}

	.footer {
		width: 100%;
		position: fixed;
		bottom: 0;
		left: 0;
		border-top: 0.5px solid #ffffff;
		color: #ffffff;
		line-height: 40px;
		text-align: center;
	}
</style>
