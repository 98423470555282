import request from './request'

const api = {
	// 登录
	login: '/login/login'
}

export {
	request,
	api
}
